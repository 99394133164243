// MAIN.JS
jQuery(function($) {

	/**********************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {

		// image
		$('.image-popup').magnificPopup({type:'image'});
        $('.image-group-popup').each(function() {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
        $('.iframe-group-popup').each(function() {
            $(this).magnificPopup({
                delegate: 'a',
                type: 'iframe',
                gallery: {
                    enabled: true
                }
            });
        });

				if ( $("#product-popup").length > 0 ) {

						 $.magnificPopup.open({
							 		items: {
			                src: '#product-popup'
			            },
									type:'inline',
									midClick: true,
									closeOnBgClick: true,
							});
				}

				$('.open-product-link').magnificPopup({
				    //type:'inline',
				    midClick: true,
				    closeOnBgClick: true,
				    gallery:{
				    		enabled:true
				    }
				});

				// $('.open-product-link').each( function(){
				// 	$(this).on("click",function(){
				// 		showSlides(1);
				// 	})
				// });
	}







	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
    if ($.fn.owlCarousel) {

			$('.owl-carousel-products').each(function(){

				var sync1 = $(this).find("#sync1");
				var sync2 = $(this).find("#sync2");
					//e.preventDefault();
					// var sync1 = $("#sync1");
			    // var sync2 = $("#sync2");
			    var slidesPerPage = 3; //globaly define number of elements per page
			    var syncedSecondary = true;

			    sync1.owlCarousel({
			        items: 1,
			        slideSpeed: 2000,
			        nav: true,
			        autoplay: true,
			        dots: true,
							margin:20,
			        loop: true,
			        responsiveRefreshRate: 200,
			        navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>', '<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
			    }).on('changed.owl.carousel', syncPosition);

			    sync2
			        .on('initialized.owl.carousel', function() {
			            sync2.find(".owl-item").eq(0).addClass("current");
			        })
			        .owlCarousel({
			            items: slidesPerPage,
			            dots: true,
			            nav: true,
			            smartSpeed: 200,
			            slideSpeed: 500,
									navText:[],
			            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
			            responsiveRefreshRate: 100
			        }).on('changed.owl.carousel', syncPosition2);

			    function syncPosition(el) {
			        //if you set loop to false, you have to restore this next line
			        //var current = el.item.index;

			        //if you disable loop you have to comment this block
			        var count = el.item.count - 1;
			        var current = Math.round(el.item.index - (el.item.count / 2) - .5);

			        if (current < 0) {
			            current = count;
			        }
			        if (current > count) {
			            current = 0;
			        }

			        //end block

			        sync2
			            .find(".owl-item")
			            .removeClass("current")
			            .eq(current)
			            .addClass("current");
			        var onscreen = sync2.find('.owl-item.active').length - 1;
			        var start = sync2.find('.owl-item.active').first().index();
			        var end = sync2.find('.owl-item.active').last().index();

			        if (current > end) {
									sync2.trigger('to.owl.carousel', current, 100);
									//sync2.data('owl.carousel').to(current, 100, true);

							}
			        if (current < start) {
								sync2.trigger('to.owl.carousel', current - onscreen, 100);
			            // sync2.data('owl.carousel').to(current - onscreen, 100, true);
			        }
			    }

			    function syncPosition2(el) {
			        if (syncedSecondary) {
			            var number = el.item.index;
									sync1.trigger('to.owl.carousel', number, 300);
			            // sync1.data('owl.carousel').to(number, 100, true);
			        }
			    }

			    sync2.on("click", ".owl-item", function(e) {
			        e.preventDefault();
			        var number = $(this).index();
							sync1.trigger('to.owl.carousel', number, 300);
			      //  sync1.data('owl.carousel').to(number, 300, true);
			    });


			});

			$('.decors').owlCarousel({
					//stagePadding: 50,/*the little visible images at the end of the carousel*/
					loop:true,
					rtl: false,
					lazyLoad:true,
					autoplay: true,
					margin:0,
					nav:true,
					navText: false,
					responsive:{
							0:{
									items:2
							},
							600:{
									items:2
							},
							800:{
									items: 3
							},
							1000:{
									items:2
							},
						1200:{
								items: 3
						}
					}
			});
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.section-logos .owl-carousel').owlCarousel({
		    loop: true,
		    margin: 20,
				autoplay: true,
		    autoplayTimeout: 2000,
		    center: true,
		    slideBy: 2,
		    fluidSpeed: 250,
		    dots: true,
		    responsive:{
		        0:{
		            items:3
		        },
		        480:{
		            items:3
		        },
				768:{
					items:5
				},
		        1000:{
		            items:7
		        }
		    }
		});
	}

	/************************************************************************/
	/* menu hide and show when scrolled	and other scroll functions			*/
	/************************************************************************/
    let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-navbar").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                $(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
        //console.log('st: ' + st);
        //console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-navbar').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-navbar').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	// write the scroll position on page load
    $(document).ready(function () {
        storeScroll();
    });


	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });






});
